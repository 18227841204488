import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../../components/layout';
import '../../assets/css/main.css';
import styled from 'styled-components';
import { Cursor } from '../../components/Cursor';
import { Menu } from '../../components/Menu';
import { PageHeader } from '..';
import { GatsbyImage } from 'gatsby-plugin-image';
import Markdown from 'react-markdown';
import { Logo } from '../../components/Logo';

const IndexPage = () => {
  const data = useStaticQuery(query);
  const [isHoveringMenu, setIsHoveringMenu] = React.useState(false);

  return (
    <Layout seo={data.strapiInfo.seo}>
      <Cursor active={isHoveringMenu} />
      <div className='uk-section'>
        <Menu isHovering={(hovering) => setIsHoveringMenu(hovering)} />
        <Logo
          imageData={data.strapiGlobal.logo}
          isHovering={(hovering) => setIsHoveringMenu(hovering)}
        />
        <ContentContainer>
          <Content>
            <div
              className='left'
              style={{
                fontSize: 20,
                color: 'black',
                lineHeight: 1.5,
              }}
            >
              <div>
                <Markdown
                  source={data.strapiInfo.Description}
                  escapeHtml={false}
                />
              </div>
            </div>
            <div className=' right'>
              <GatsbyImage
                style={{ gridArea: '1/1', maxHeight: 550, marginTop: -20 }}
                layout='fullWidth'
                objectFit='contain'
                image={
                  data.strapiInfo.image.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={data.strapiGlobal.siteName}
              />
            </div>
          </Content>
        </ContentContainer>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiGlobal {
      siteName
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, placeholder: NONE)
          }
        }
      }
    }
    strapiInfo {
      Description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: NONE)
          }
        }
      }
    }
  }
`;

const ContentContainer = styled.div`
  padding-left: 130px;
  padding-right: 130px;

  @media (max-width: 1200px) {
    padding-left: 55px;
    padding-right: 55px;
  }

  @media (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Content = styled.div`
  display: flex;
  margin-top: 110px;

  & .left {
    flex: 4;
    padding-right: 130px;
    margin-top: 30px;
  }
  & .right {
    flex: 3;
  }
  & * {
    font-weight: 400;
  }

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    & .left,
    .right {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    & .left {
      padding-right: 0px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    & .left,
    .right {
      flex: 1;
      justify-content: center;
    }
  }
`;

export default IndexPage;
